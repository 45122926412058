export type OmniFunder = {
  name: string
  url: string
}

type OmniFunders = Map<string, OmniFunder>

export const leadFunders: OmniFunders = new Map([
  [
    'iconiq-growth',
    { name: 'Iconiq Growth', url: 'https://www.iconiqcapital.com/growth' },
  ],
  ['theory', { name: 'Theory Ventures', url: 'https://theory.ventures/' }],
])

export const funders: OmniFunders = new Map([
  ['first-round', { name: 'First Round', url: 'https://firstround.com/' }],
  ['redpoint', { name: 'Redpoint Ventures', url: 'https://redpoint.com/' }],
  ['google-ventures', { name: 'Google Ventures', url: 'https://gv.com/' }],
  ['quiet', { name: 'Quiet Capital', url: 'https://www.quiet.com/' }],
  ['boxgroup', { name: 'BoxGroup', url: 'https://www.boxgroup.com/' }],
  ['scribble', { name: 'Scribble Ventures', url: 'https://www.scribble.vc/' }],
  ['tokyo-black', { name: 'Tokyo Black', url: 'https://tokyo.black/' }],

  [
    'snowflake-ventures',
    {
      name: 'Snowflake Ventures',
      url: '/blog/snowflake-invests-in-omni',
    },
  ],
])
