import styles from '~/styles/components/human.css?url'
import grid from '~/styles/components/grid.css?url'

export const humanLinks = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: grid },
]

export { Human } from './human'
export { personPhotoSrc } from './photo'
