import { funders, leadFunders } from '~/config/funders'
import type { OmniFunder } from '~/config/funders'

import styles from '~/styles/components/funders.css?url'

export const fundersLinks = () => [{ rel: 'stylesheet', href: styles }]

export const Funders = () => (
  <section>
    <h2 className="with-divider">Backed By</h2>
    <ul className="funders-grid lead-funders">
      {Array.from(leadFunders).map(([index, funder]) => (
        <Funder id={index} key={index} {...funder} />
      ))}
    </ul>
    <ul className="funders-grid">
      {Array.from(funders).map(([index, funder]) => (
        <Funder id={index} key={index} {...funder} />
      ))}
    </ul>
  </section>
)

const Funder = ({ id, name, url }: { id: string } & OmniFunder) => (
  <li className={`funder-card ${id}`}>
    <a
      href={url}
      target={url.startsWith('http') ? '_blank' : undefined}
      rel="noreferrer"
    >
      <img src={`/images/funders/${id}.png`} alt={name} />
    </a>
  </li>
)
